import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useReferrals } from '@/providers/referral';
import { APP_LINKS } from '@/helpers/links';
import { useGoogleAuth } from '@/query/queries/auth/useGoogleAuth';
import { useAppleAuth } from '@/query/queries/auth/useAppleAuth';
import { GoogleButton } from './google';
import { AppleButton } from './apple';
import s from './index.module.scss';

export const SocialNetworks = ({ className, onSuccess }) => {
  const { t } = useTranslation('kit');
  const { mutateAsync: googleAuth } = useGoogleAuth();
  const { mutateAsync: appleAuth } = useAppleAuth();

  const { currentRefCode } = useReferrals();
  const refCode = currentRefCode?.id;

  const onGoogleResponse = async response => {
    if (response?.access_token) {
      const res = await googleAuth({
        googleRes: response,
        refCode,
      });
      res && onSuccess(res);
    }
  };

  const onAppleResponse = async response => {
    if (response?.authorization) {
      const res = await appleAuth({
        appleRes: response,
        refCode,
      });
      res && onSuccess(res);
    }
  };

  return (
    <div className={classnames(s.wrapper, className)}>
      <GoogleButton
        label={t('social_networks.continue_with_google')}
        onGoogleResponseSuccess={onGoogleResponse}
        onGoogleResponseFailure={res => console.error(res)}
      />
      <AppleButton
        label={t('social_networks.continue_with_apple')}
        onResponse={onAppleResponse}
        redirectURL={`${window.location.origin}${APP_LINKS.auth}`}
      />
    </div>
  );
};
