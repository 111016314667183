import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const DEFAULT_LOCALE = 'en';
export const LOCALES = ['en', 'el', 'es', 'pt', 'de', 'nl', 'ee'];

const namespaces = [
  'affiliate',
  'auth',
  'bank_details',
  'blog',
  'brands',
  'client_questions',
  'common',
  'compliant',
  'constants',
  'dashboard',
  'footer',
  'funds',
  'header',
  'help',
  'investing',
  'kit',
  'loan_terms',
  'loans',
  'modal',
  'navigation',
  'profile',
  'repayment_schedule',
  'reports',
  'round_history',
  'utils',
  'warning',
  'withdraw',
];

i18n
  .use(HttpApi) // Load translations via HTTP
  .use(LanguageDetector) // Detect user language
  .use(initReactI18next) // Bind React-i18next
  .init({
    fallbackLng: DEFAULT_LOCALE, // Default language
    supportedLngs: LOCALES, // List of supported languages
    debug: false, // Enable console logs for debugging
    interpolation: {
      escapeValue: false, // React already escapes values
    },
    ns: namespaces, // Namespaces
    defaultNS: 'common',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // Path to translation files
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator'],
      caches: ['cookie', 'localStorage'], // Cache the language
    },
  });

export default i18n;
