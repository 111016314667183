import { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import classnames from 'classnames';
import * as Yup from 'yup';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { setModals } from '@/store/actions/commonActions';
import { MODAL_TYPES } from '@/components/ModalProvider';
import { OtpCodeInput } from '@/components/OtpCodeInput';
import { CountdownHour } from '@/components/CountdownHour';
import { useGetEmailOtpStatus } from '@/query/queries/auth/useGetEmailOtpStatus';
import { useSubmitEmailOtpCode } from '@/query/queries/auth/useSubmitEmailOtpCode';
import { useResendEmailOtpCode } from '@/query/queries/auth/useResendEmailOtpCode';
import { Message } from '@/ui/Message';
import s from './index.module.scss';

const SMS_LIMIT = 3;

const VALIDATION_SCHEMA = Yup.object({
  code: Yup.array(),
});

export const StepEmailOtp = ({ onChangeEmail, onSuccess }) => {
  const mounted = useRef(true);
  const [success, setSuccess] = useState(false);
  const { data: OtpStatus } = useGetEmailOtpStatus();
  const { mutateAsync: submitCode } = useSubmitEmailOtpCode();
  const { mutateAsync: resendCode } = useResendEmailOtpCode();
  const [expireTime, setExpireTime] = useState(null);
  const { t } = useTranslation('auth');
  const dispatch = useDispatch();

  useEffect(() => {
    const lastCreated = OtpStatus?.data.last_created_at;
    if (lastCreated) {
      const currentTime = moment();
      const expireTime = moment(OtpStatus.data.last_created_at).add(
        OtpStatus.data.count >= SMS_LIMIT ? 10 : 1,
        'minutes',
      );

      mounted.current && currentTime.isBefore(expireTime)
        ? setExpireTime(expireTime.toISOString())
        : setExpireTime(null);
    } else {
      mounted.current && setExpireTime(null);
    }
  }, [OtpStatus?.data.last_created_at]);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  const onCounterFinish = () => {
    setExpireTime(null);
  };

  const onSubmit = async ({ code }, { setErrors }) => {
    const res = await submitCode({ code: code.join('') });
    if (res?.success) {
      setSuccess(true);
      setTimeout(() => {
        onSuccess(res.data); // use delay to show visual green effects
      }, 500);
    }

    if (res?.error) setErrors({ code: res.error });
  };

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    onSubmit,
    validationSchema: VALIDATION_SCHEMA,
    initialValues: {
      code: [],
    },
  });

  const onResendCode = async () => {
    dispatch(
      setModals({
        type: MODAL_TYPES.email_otp_modal,
        email,
      }),
    );
    await resendCode();
    setFieldValue('code', []);
  };

  if (!OtpStatus) return null;

  const email = OtpStatus.data?.email;
  const canResend = OtpStatus.data?.has_attempts;

  return (
    <form onSubmit={handleSubmit} className={s.wrapper}>
      <h1 className="f-42 f-500">{t('email_otp_step.title')}</h1>
      <div className={s.text}>
        <Trans
          ns="auth"
          i18nKey="email_otp_step.message"
          values={{ email }}
          components={{ span: <span className="f-500" /> }}
        />
      </div>
      <div className={s.input}>
        <OtpCodeInput
          values={values.code}
          error={errors.code && touched.code}
          success={success}
          onChange={value => setFieldValue('code', value.split(''))}
          onComplete={() => {
            handleSubmit();
          }}
        />
        {errors.code && touched.code && (
          <Message
            type="error"
            title={t('email_otp_step.invalid_code')}
            description={t('email_otp_step.please_try_again')}
            className={s.input__error}
          />
        )}
      </div>

      <div className={s.buttons}>
        <button
          type="button"
          onClick={onChangeEmail}
          className={classnames(s.email_btn, 'f-16-24')}
        >
          {t('email_otp_step.change_email')}
        </button>
      </div>

      {canResend && (
        <div
          className={classnames(s.timer, status.next_channel, 'f-18-24 f-400')}
        >
          {OtpStatus.data.count < SMS_LIMIT ? (
            <>
              {t('email_otp_step.did_not_receive_code')}{' '}
              {expireTime ? (
                <CountdownHour
                  endTime={expireTime}
                  onFinish={onCounterFinish}
                  label={time => t('email_otp_step.resend_code_in', { time })}
                  className={s.countdown}
                />
              ) : (
                OtpStatus.data.count < SMS_LIMIT && (
                  <button
                    type="button"
                    onClick={onResendCode}
                    className={classnames(s.resend_btn, 'underline')}
                  >
                    <span>{t('email_otp_step.resend')}</span>
                  </button>
                )
              )}
            </>
          ) : (
            expireTime && (
              <CountdownHour
                endTime={expireTime}
                onFinish={onCounterFinish}
                label={time => t('email_otp_step.code_expires_in', { time })}
                className={s.countdown}
              />
            )
          )}
        </div>
      )}

      {OtpStatus.data.count >= SMS_LIMIT && (
        <div className={classnames('f-16-24 f-400', s.last_attempt)}>
          <Trans
            ns="auth"
            i18nKey="email_otp_step.you_have_reached"
            components={{ span: <span className="f-500" /> }}
          />
        </div>
      )}
    </form>
  );
};
