import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { getFullname, prettyMoney, scrollTop } from '@/helpers/utils';
import { MoneyField } from '@/components/UI/MoneyField';
import { RoundButton } from '@/components/UI/RoundButton';
import { BankTransfer } from './BankTransfer';
import { Preloader } from '@/components/Preloader';
import { TotalPaymentAmount } from './TotalPaymentAmount';
import { PaymentMethod } from './PaymentMethod';
import { BonusBanner } from '@/containers/BonusProgram/banner';
import { NewInvestorTopUpFeeBanner } from '@/components/Banners/NewInvestorTopUpFeeBanner';
import { NoAvailableTopUpMethods } from '@/containers/PaymentModule/components/NoAvailableTopUpMethods';
import Analytics from '@/analyticsGA';
import s from './index.module.scss';
import { useGetMe } from '@/hooks/useGetMe.js';

const VALIDATION_SCHEMA = t =>
  Yup.object().shape({
    amount: Yup.number()
      .min(1, t('minimum_amount_is_1_eur'))
      .required(t('please_enter_amount')),
    method: Yup.string().required(t('required_field')),
  });

const AMOUNT_PRESETS = [50, 100, 300, 500];

const PaymentForm = ({
  onSubmit,
  paymentMethods,
  loading,
  initialValues,
  onBack,
  children,
  className,
}) => {
  const { t } = useTranslation('funds');
  const { user } = useGetMe();
  const fullname = getFullname(user.first_name, user.last_name);

  const calculateCommission = (amount, method) => {
    if (!method) {
      return 0;
    }
    return (
      ((Number(amount) * (method.commission / 100) +
        method.fixedComission +
        Number(amount)) *
        method.commission) /
        100 +
      method.fixedComission
    );
  };

  const hasPaymentMethods = !!paymentMethods?.length;

  return (
    <Formik
      validationSchema={VALIDATION_SCHEMA(t)}
      initialValues={initialValues}
      onSubmit={(values, formikBag) => {
        formikBag.setFieldValue('hasConfirmed', true);
        onSubmit && onSubmit(values, formikBag);
      }}
    >
      {formikBag => {
        const { values, errors, touched, setFieldValue, handleSubmit } =
          formikBag;

        const isBankTransfer = values.hasConfirmed && values.method === 'bank';

        return (
          <form
            className={classnames(s.form, className)}
            onSubmit={handleSubmit}
          >
            {isBankTransfer ? (
              <BankTransfer amount={values.amount} />
            ) : (
              <>
                <h1 className={classnames(s.title, 'f-26 f-500')}>
                  {t('add_cash_to_your_account')}
                </h1>
                <NewInvestorTopUpFeeBanner className={s.bonus} />
                <BonusBanner className={s.bonus} />

                {!hasPaymentMethods && <NoAvailableTopUpMethods />}

                {hasPaymentMethods && (
                  <>
                    <section className={s.amount}>
                      <h2 className={classnames(s.subtitle, 'f-20 f-500')}>
                        {t('select_the_amount_of_your_top_up')}
                      </h2>
                      <div className={s.amount_container}>
                        <div className={s.amount_presets}>
                          {AMOUNT_PRESETS.map((amount, index) => (
                            <div
                              key={index}
                              onClick={() => {
                                Analytics.addCashAmount(
                                  'Amount change (presets)',
                                );
                                setFieldValue('amount', `${amount}`);
                              }}
                              className={classnames(
                                s.amount_preset,
                                Number(values.amount) === amount && 'selected',
                                'f-20',
                                'f-500',
                              )}
                            >
                              {prettyMoney(amount)}
                            </div>
                          ))}
                        </div>
                        <div className={s.amount_right}>
                          <div className={s.amount_field}>
                            <MoneyField
                              onChange={(_, value) => {
                                setFieldValue('amount', value);
                              }}
                              onFocus={() =>
                                Analytics.addCashAmount('Amount input change')
                              }
                              name="amount"
                              label={t('or_specify_your_own_amount')}
                              value={values.amount}
                              error={
                                errors.amount && touched.amount && errors.amount
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </section>

                    <section className={s.method}>
                      <h2 className={classnames(s.subtitle, 'f-20 f-500')}>
                        {t('select_payment_method')}
                      </h2>
                      <div className={s.method_list}>
                        {paymentMethods.map(item => {
                          const commission =
                            item.type === values.method && values.commission
                              ? values.commission
                              : calculateCommission(
                                  Number(values.amount),
                                  item,
                                );

                          return (
                            <PaymentMethod
                              key={item.type}
                              item={item}
                              amount={Number(values.amount)}
                              commission={commission}
                              formikBag={formikBag}
                            />
                          );
                        })}
                      </div>
                    </section>

                    <TotalPaymentAmount
                      amount={Number(values.amount)}
                      method={values.method}
                      setCommission={commission =>
                        setFieldValue('commission', commission)
                      }
                    />
                  </>
                )}
              </>
            )}

            {children}

            {hasPaymentMethods && (
              <>
                <div className={s.buttons}>
                  {!isBankTransfer && (
                    <RoundButton
                      label={() =>
                        loading ? (
                          <Preloader style={{ height: '24px' }} white />
                        ) : values.method !== 'bank' ? (
                          <span className="f-500">
                            {t('go_to_secure_payment')}
                          </span>
                        ) : (
                          <span className="f-500">{t('go_to_next_step')}</span>
                        )
                      }
                      type="submit"
                      onSubmit={handleSubmit}
                      className={s.button_submit}
                      fillBackground
                      fullWidth
                      disabled={!values.amount || !values.method}
                    />
                  )}
                </div>

                {values.method !== 'bank' && !values.hasConfirmed && (
                  <div
                    className={classnames(
                      s.buttons_desc,
                      !onBack && !values.hasConfirmed && 'line',
                    )}
                  >
                    <div />
                    <div className="f-12 f-400">
                      {t('payment_processing_provided_by_everypay')}
                    </div>
                  </div>
                )}

                <div className={classnames(s.description, 'f-16-24 f-400')}>
                  <div>{t('currency_of_your_account_is_eur')}</div>
                  <div>
                    {t('cash_accepted_only_from_bank_accounts', { fullname })}
                  </div>
                  <div>{t('note_about_bank_fees')}</div>
                  <div>{t('transfer_of_funds_depends_on_method')}</div>
                </div>
              </>
            )}

            {!loading && (onBack || values.hasConfirmed) && (
              <RoundButton
                label={t('go_back')}
                className={s.button_back}
                style={{ marginTop: '20px' }}
                onClick={() => {
                  if (onBack && !values.hasConfirmed) {
                    return onBack();
                  }
                  scrollTop();
                  setFieldValue('hasConfirmed', false);
                }}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default PaymentForm;
