import { useRef } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { InputTextField } from '@/components/UI/InputTextField';
import { RoundButton } from '@/components/UI/RoundButton';
import { useOutsideClick } from '@/hooks/useOutsideClick';
import './index.scss';

const VALIDATION_SCHEMA = t =>
  Yup.object({
    first_name: Yup.string().max(
      40,
      t('profile_summary_form.first_name_exceeds_limit'),
    ),
    last_name: Yup.string().max(
      40,
      t('profile_summary_form.last_name_exceeds_limit'),
    ),
    company_name: Yup.string().max(
      255,
      t('profile_summary_form.company_name_exceeds_limit'),
    ),
    position: Yup.string().max(
      255,
      t('profile_summary_form.position_exceeds_limit'),
    ),
  });

const ProfileSummaryForm = ({
  userLocation,
  user,
  preSaved,
  onSubmit,
  onCancel,
  onOutsideClick,
}) => {
  const ref = useRef();
  const { t } = useTranslation('modal');

  const getInitialValues = user => ({
    first_name: (user && user.first_name) || '',
    last_name: (user && user.last_name) || '',
    company_name: (user && user.investor.company_name) || '',
    position: (user && user.investor.position) || '',
  });

  return (
    <Formik
      enableReinitialize
      initialValues={preSaved || getInitialValues(user, userLocation)}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => {
        useOutsideClick(
          ref,
          () => onOutsideClick && onOutsideClick(values),
          !!onOutsideClick,
        );

        return (
          <form
            className="profile_summary_form"
            onSubmit={handleSubmit}
            ref={ref}
          >
            <h1 className="profile_summary_form__title f-32 f-500">
              {t('profile_summary_form.edit_profile_details')}
            </h1>
            <p className="profile_summary_form__subTitle">
              {t('profile_summary_form.confidential_information')}
            </p>
            <div className="profile_summary_form__grid">
              <div className="profile_summary_form__grid_left">
                <InputTextField
                  label={t('profile_summary_form.first_name')}
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  onClear={() => setFieldValue('first_name', '')}
                  error={
                    errors.first_name && touched.first_name && errors.first_name
                  }
                  className="profile_summary_form__field"
                />
                <InputTextField
                  label={t('profile_summary_form.last_name')}
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  onClear={() => setFieldValue('last_name', '')}
                  error={
                    errors.last_name && touched.last_name && errors.last_name
                  }
                  className="profile_summary_form__field"
                />
              </div>
              <div className="profile_summary_form__grid_right">
                <InputTextField
                  label={t('profile_summary_form.company_name')}
                  name="company_name"
                  value={values.company_name}
                  onChange={handleChange}
                  onClear={() => setFieldValue('company_name', '')}
                  error={
                    errors.company_name &&
                    touched.company_name &&
                    errors.company_name
                  }
                  className="profile_summary_form__field"
                />
                <InputTextField
                  label={t('profile_summary_form.position')}
                  name="position"
                  value={values.position}
                  onChange={handleChange}
                  onClear={() => setFieldValue('position', '')}
                  error={errors.position && touched.position && errors.position}
                  className="profile_summary_form__field"
                />
                <div />
              </div>
            </div>

            <div className="profile_summary_form__buttons">
              {onCancel && (
                <RoundButton
                  label={t('profile_summary_form.cancel')}
                  type="button"
                  fullWidth
                  onClick={onCancel}
                  className="profile_summary_form__button"
                />
              )}
              <RoundButton
                label={t('profile_summary_form.save')}
                type="submit"
                onSubmit={handleSubmit}
                fillBackground
                fullWidth
                className="profile_summary_form__button"
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default ProfileSummaryForm;
