import moment from 'moment/min/moment-with-locales';
import momentTZ from 'moment-timezone';
import { Trans } from 'react-i18next';
import {
  DATE_FORMAT_DD_MMM,
  DATE_FORMAT_DD_MMMM_YYYY,
  DATE_REQUEST,
} from '@/helpers/common';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import { CONTACT_EMAIL, TIMEZONE_ESTONIA } from '@/helpers/constants';
import {
  countdownForNotification,
  prettyDate,
  prettyFloatMoney,
} from '@/helpers/utils';

export const NOTIFICATIONS = t => ({
  next_round_starts: start_date => idx => (
    <li key={idx}>
      {t('notification.next_round_starts', {
        startDate: countdownForNotification(
          momentTZ.tz(start_date, TIMEZONE_ESTONIA).add(12, 'hours'),
        ),
      })}
    </li>
  ),
  loan_disbursement_starts: end_date => idx => (
    <li key={idx}>
      {t('notification.loan_disbursement_starts', {
        endDate: moment(end_date).add(1, 'day').format(DATE_FORMAT_DD_MMM),
      })}
    </li>
  ),
  result_being_processed: () => idx => (
    <li key={idx}>{t('notification.result_being_processed')}</li>
  ),
  investment_strategy_restrictive: () => idx => (
    <li key={idx}>
      <Trans
        ns="common"
        i18nKey="notification.investment_strategy_restrictive"
        values={{ email: CONTACT_EMAIL }}
        components={{
          link: (
            <MyLink
              className="email"
              href={`mailto:${CONTACT_EMAIL}`}
              isExternal
            />
          ),
        }}
      />
    </li>
  ),
  unallocated_cash_reinvested: amount => idx => (
    <li key={idx}>
      <Trans
        ns="common"
        i18nKey="notification.unallocated_cash_reinvested"
        values={{ amount: prettyFloatMoney(amount) }}
        components={{
          link: (
            <MyLink
              href={`${APP_LINKS.allocateCash}/?to=reinvest`}
              className="c-grey"
            />
          ),
        }}
      />
    </li>
  ),
  round_results: end_date => idx => (
    <li key={idx}>
      <Trans
        ns="common"
        i18nKey="notification.round_results"
        values={{
          endDate: countdownForNotification(
            moment(end_date, DATE_REQUEST).add(1, 'day').format(DATE_REQUEST),
          ),
        }}
        components={{ span: <span className="f-500" /> }}
      />
    </li>
  ),
  transactions_available_to_verified_users: () => idx => (
    <li key={idx}>
      <Trans
        ns="common"
        i18nKey="notification.transactions_available_to_verified_users"
        components={{
          link: (
            <MyLink href={APP_LINKS.profile} className="c-grey underline" />
          ),
        }}
      />
    </li>
  ),
  checking_transactions_available_to_verified_users: () => idx => (
    <li key={idx}>
      {t('notification.checking_transactions_available_to_verified_users')}
    </li>
  ),
  provide_your_details_for_transactions: () => idx => (
    <li key={idx}>
      <Trans
        ns="common"
        i18nKey="notification.provide_your_details_for_transactions"
        components={{
          link: (
            <MyLink
              href={APP_LINKS.dashboard + '/#veriff'}
              className="c-grey underline"
            />
          ),
        }}
      />
    </li>
  ),
  verify_phone_number: () => idx => (
    <li key={idx}>
      <Trans
        ns="common"
        i18nKey="notification.verify_phone_number"
        components={{
          link: (
            <MyLink
              href={APP_LINKS.profile + '/#phone_verification'}
              className="c-grey underline"
            />
          ),
        }}
      />
    </li>
  ),
  verify_email: () => idx => (
    <li key={idx}>
      <Trans
        ns="common"
        i18nKey="notification.verify_email"
        components={{
          link: (
            <MyLink
              href={APP_LINKS.profile + '/#email_verification'}
              className="c-grey underline"
            />
          ),
        }}
      />
    </li>
  ),
  bank_accounts_available_to_verified_users: () => idx => (
    <li key={idx}>
      <Trans
        ns="common"
        i18nKey="notification.bank_accounts_available_to_verified_users"
        components={{
          link: (
            <MyLink href={APP_LINKS.profile} className="c-grey underline" />
          ),
        }}
      />
    </li>
  ),
  round_has_started: () => idx => (
    <li key={idx}>
      <Trans
        ns="common"
        i18nKey="notification.round_has_started"
        components={{
          link: (
            <MyLink to={APP_LINKS.investing} style={{ color: '#9E98A3' }} />
          ),
        }}
      />
    </li>
  ),
  bonus_can_be_invested_group_a_only: bonus_amount => idx => (
    <li key={idx}>
      {t('notification.bonus_can_be_invested_group_a_only', {
        amount: prettyFloatMoney(bonus_amount),
      })}
    </li>
  ),
  you_can_modify_investing_until: date => idx => (
    <li key={idx}>
      <Trans
        ns="common"
        i18nKey="notification.you_can_modify_investing_until"
        values={{ date: prettyDate(moment(date), DATE_FORMAT_DD_MMMM_YYYY) }}
        components={{ span: <span className="f-500" /> }}
      />
    </li>
  ),
  now_you_can_add_more_funds: () => idx => (
    <li key={idx}>{t('notification.now_you_can_add_more_funds')}</li>
  ),
  results_will_be_processed: end_date => idx => (
    <li key={idx}>
      <Trans
        ns="common"
        i18nKey="notification.results_will_be_processed"
        values={{
          endDate: prettyDate(
            moment(end_date).add(1, 'day'),
            DATE_FORMAT_DD_MMMM_YYYY,
          ),
        }}
        components={{ span: <span className="f-500" /> }}
      />
    </li>
  ),
  october_weekends: () => idx => (
    <li key={idx}>
      <Trans
        ns="common"
        i18nKey="notification.october_weekends"
        components={{
          span: <span className="f-500" />,
          link: <MyLink to={APP_LINKS.loansSchedule} underline />,
        }}
      />
    </li>
  ),
  regular_top_ups: () => idx => (
    <li key={idx}>
      <Trans
        ns="common"
        i18nKey="notification.regular_top_ups"
        components={{
          link: (
            <MyLink
              href="https://investor.scrambleup.com/blog/why-topping-up-with-100-is-key-to-earning-higher-returns"
              underline
              isExternal
              style={{ color: '#9E98A3' }}
            />
          ),
        }}
      />
    </li>
  ),
});
