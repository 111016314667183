import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LOCALE } from '@/i18n';
import classnames from 'classnames';
import { LANGUAGES } from './list';
import s from './index.module.scss';

const LanguageSelectModal = ({ onClose }) => {
  const { i18n } = useTranslation('header');
  const { t } = useTranslation('modal');
  const current = i18n.language || DEFAULT_LOCALE;

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    window.location.reload();
  };

  useEffect(() => {
    if (!i18n.language) {
      i18n.changeLanguage(DEFAULT_LOCALE);
    }
  }, [i18n.language]);

  return (
    <div className={s.wrapper}>
      <div className={s.title}>{t('language_select.select_language')}</div>
      <div className={s.list}>
        {LANGUAGES.map(lng => (
          <div
            key={lng.code}
            className={classnames(
              s.language,
              lng.code === current && 'selected',
              'hov',
            )}
            onClick={() => {
              changeLanguage(lng.code);
              onClose();
            }}
          >
            <div className={s.label}>{lng.label}</div>
            <div className={s.country}>{lng.country}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LanguageSelectModal;
