import * as Yup from 'yup';
import classnames from 'classnames';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useApplyReferralCode } from '@/query/queries/referral/useApplyReferralCode';
import { Message } from '@/ui/Message';
import { TextInput } from '@/ui/TextInput';
import { prettyFloatMoney } from '@/helpers/utils';
import s from './index.module.scss';

export const StepReferral = ({ nextStep }) => {
  const { t } = useTranslation('auth');
  const { mutateAsync: applyReferralCode, data } = useApplyReferralCode();

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    validationSchema: Yup.object({
      referralCode: Yup.string()
        .min(8, t('referral_step.code_is_invalid'))
        .max(255, t('referral_step.code_is_invalid'))
        .required(t('referral_step.code_is_required')),
    }),
    initialValues: {
      referralCode: '',
    },
    onSubmit: async (values, formik) => {
      const res = await applyReferralCode(values.referralCode);
      if (!res.is_activated) {
        formik.setFieldError(
          'isValid',
          res?.message || t('referral_step.invalid_ref_code'),
        );
      }
      formik.setSubmitting(false);
    },
  });

  const isActivated = data?.is_activated;

  return (
    <form className={s.form} onSubmit={handleSubmit}>
      <h1 className={classnames(s.title, 'f-32 f-500')}>
        {t('referral_step.enter_code')}
      </h1>
      <div className={s.row}>
        <TextInput
          name="referralCode"
          value={values.referralCode}
          onChange={handleChange}
          placeholder={t('referral_step.referral_code')}
          className={s.input}
          disabled={isActivated}
        />
        <button
          type="submit"
          disabled={
            !!errors.isValid ||
            isActivated ||
            (errors.referralCode && touched.referralCode)
          }
          onSubmit={handleSubmit}
          className={s.submit}
        >
          {t('referral_step.apply')}
        </button>
      </div>

      {isActivated ? (
        <>
          <Message
            type="success"
            title={t('referral_step.code_successfully_applied')}
            description={t('referral_step.bonus_activation_message', {
              amount: prettyFloatMoney(data.amount),
            })}
          />
          <button type="button" onClick={nextStep} className={s.continue}>
            {t('referral_step.continue')}
          </button>
        </>
      ) : (
        <>
          {((touched.referralCode && errors.referralCode) ||
            errors.isValid) && (
            <Message
              type="error"
              title={t('referral_step.invalid_code')}
              description={t('referral_step.please_try_again')}
            />
          )}
          <button type="button" onClick={nextStep} className={s.continue}>
            {t('referral_step.continue_without_code')}
          </button>
        </>
      )}
    </form>
  );
};
